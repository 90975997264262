<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>    <!-- Using logo scoped slot -->
    <!-- Using logo scoped slot -->
    <div
      slot="vertical-menu-header"
      class="d-flex align-items-center justify-content-center"
    >
      <ul class="nav navbar-nav flex-row">
        <!-- Logo & Text -->
        <li class="nav-item">
          <b-link
            class="navbar-brand"
            :to="{ name: 'home' }"
          >
            <span class="brand-logo">
              <b-img
                :src="appLogoImage"
                alt="logo"
              />
            </span>
          </b-link>
        </li>
      </ul>
    </div>

    <!-- Using logo scoped slot -->
    <div slot="footer">
      <p class="mb-0 text-center">
        &copy; CURIA {{ new Date().getFullYear() }} - Version 2.9
      </p>
    </div>
  </layout-vertical>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    LayoutVertical,
    Navbar,
    BLink,
    BImg,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
}
</script>
